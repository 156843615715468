import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { json } from "@vercel/remix";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@vercel/remix";
import tailwind from "@figment-fitness/ui/styles/tailwind.css?url";

import "@figment-fitness/ui"; // for importing style.css
import "@figment-fitness/ui/dist/index.css"; // for importing style.css
import "@radix-ui/themes/styles.css";
import { Theme } from "@figment-fitness/ui/components/theme";
import { initConfig } from "~/client-config";
import { Toaster } from "@figment-fitness/ui/components/toast";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: tailwind },
];

export const loader = async () => {
  return json({
    ENV: {
      PUBLIC_APP_ENV: process.env.PUBLIC_APP_ENV,
    },
  });
};

export const meta: MetaFunction = () => {
  return [
    { title: "Figment-フィットネス版" },
    { name: "description", content: "Figment system" },
  ];
};

export const config = {
  maxDuration: 30,
  memory: 1024,
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useLoaderData<typeof loader>();
  const appENV = data?.ENV?.PUBLIC_APP_ENV;
  if (appENV) initConfig(appENV);

  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Theme>
          {children}
          <Toaster />
        </Theme>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}